import React, { useState } from 'react';
import {
  Button,
  Paper,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Funcoes from '../../functions/functions';

const AtualizarSaldoLQ = ({ handleSnackbar, loading, setLoading }) => {
  const [startdate, setstartdate] = useState(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(now);
    //sevenDaysAgo.setDate(now.getDate() - 7);
    return sevenDaysAgo.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(() => {
    const now = new Date();
    now.setHours(23, 59, 59, 999);
    return now.toISOString().split('T')[0];
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = (data || []).slice().sort((a, b) => {
    if (!orderBy) return 0;

    const aValue = a[orderBy] || '';
    const bValue = b[orderBy] || '';

    if (order === 'asc') {
      return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    } else {
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    }
  });

  const AtualizarSaldoLQ = async () => {
    setLoading(true);
    try {
      const response = await Funcoes.updateBalanceAccountall(startdate, chosenbank);
      console.log(response)

      setData(response.data);


      handleSnackbar('Dados consultados com sucesso', 'success');
    } catch (error) {
      handleSnackbar('Erro ao consultar dados', 'error');
    } finally {
      setLoading(false);
    }
  };

  const renderTable = () => {
    return (
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'document'}
                  direction={orderBy === 'document' ? order : 'asc'}
                  onClick={() => handleSort('document')}
                >
                  CPF
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={() => handleSort('balance')}
                >
                  Saldo
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.document || 'N/A'}</TableCell>
                <TableCell>
                  {item.balance !== undefined
                    ? `R$ ${parseFloat(item.balance).toFixed(2)}`
                    : 'R$ 0,00'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };


  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Atualizar Saldos Contas LQ</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Atualizar por dia"
              type="date"
              value={startdate}
              onChange={(e) => setstartdate(e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={AtualizarSaldoLQ}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Atualizando...' : 'Atualizar'}
            </Button>
          </Grid>
        </Grid>

        {data && renderTable()}
      </AccordionDetails>
    </Accordion>
  );
};

export default AtualizarSaldoLQ;
