import React, { useState, useEffect } from 'react';
import * as Funcoes from '../../functions/functions';
import * as Format from "../../functions/Format";
import { useNavigate } from 'react-router-dom'; // Importação do useNavigate
import {
    Box,
    Grid,
    Typography,
    Container,
} from '@mui/material';

const Home = ({ user, checkAuthentication }) => {
    const navigate = useNavigate(); // Hook para navegação

    const [state, setState] = useState({
        user: JSON.parse(localStorage.getItem('user')),
        painelgeral: [],
        loading: true,
        totalContasFilhas: 0,
        totalBalance: 0,
        multiplebalance: [],
        qeshCount: 0,
        qeshBalance: 0,
        bmbCount: 0,
        bmbBalance: 0,
        chosenbank: localStorage.getItem('chosenbank'), // Banco selecionado salvo no localStorage
        availableBanks: '',
        tariff: {}
    });

    useEffect(() => {
        async function fetchDatacheck() {
            await checkAuthentication();
        }
        fetchDatacheck();
    }, []);


    const getTariffUser = async (user) => {
        const tariff = await Funcoes.getTariffUser(user.id);
        //console.log(tariff);
        setState(prevState => ({
            ...prevState,
            tariff
        }));
    };


    useEffect(() => {
        //console.log(user);
        if (user && Object.keys(user).length > 0) {
            let multiplebalance = user.configData?.multiplebalance || "[]";

            // Parseia o JSON se for string
            if (typeof multiplebalance === "string") {
                try {
                    multiplebalance = JSON.parse(multiplebalance);
                } catch (error) {
                    console.error("Erro ao parsear multiplebalance:", error);
                    multiplebalance = [];
                }
            }

            // Verifica quais bancos estão disponíveis
            const availableBanks = (user.accountData || []).reduce((acc, account) => {
                if (account.chosenbank === 'qesh') acc.qesh = true;
                if (account.chosenbank === 'bmb') acc.bmb = true;
                return acc;
            }, { qesh: false, bmb: false });

            setState(prevState => ({
                ...prevState,
                user: user,
                multiplebalance: Array.isArray(multiplebalance) ? multiplebalance : [],
                painelgeral: user.configData?.keypix ? JSON.parse(user.configData.keypix) : [],
                availableBanks, // Atualiza os bancos disponíveis no estado
            }));

            buscarContas();
            getTariffUser(user);
        }
    }, [user]);


    const buscarContas = async () => {
        const managerId = user?.id;
        try {
            const data = await Funcoes.getAccountSummaryUser(managerId);

            setState(prevState => ({
                ...prevState,
                totalContasFilhas: data?.totalAccounts || 0,
                totalBalance: data?.totalBalance || 0,
                qeshCount: data?.qeshCount || 0,
                qeshBalance: data?.qeshBalance || 0,
                bmbCount: data?.bmbCount || 0,
                bmbBalance: data?.bmbBalance || 0,
                loading: false,
            }));
        } catch (error) {
            setState(prevState => ({ ...prevState, loading: false }));
        }
    };

    const handleBankSelection = (bank) => {
        // Salva o banco selecionado no localStorage e atualiza o estado
        localStorage.setItem('chosenbank', bank);
        setState(prevState => ({ ...prevState, chosenbank: bank }));
        // Navega para a página de Contas
        navigate('/contas'); // Ajuste o caminho se necessário
    };

    const renderCard = (title, value, description, isButton = false, bank = null, pix = false) => (
        <Box
            sx={{
                width: { xs: '100%', sm: '48%', md: '48%' },
                height: 'auto',
                backgroundColor: isButton && state.chosenbank === bank
                    ? (bank === 'qesh' ? '#27384E' : bank === 'bmb' ? '#004717' : 'white')
                    : 'white',
                borderRadius: 10,
                boxShadow: 10,
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                transition: 'background-color 0.3s',
                marginBottom: { xs: 2, md: 3 },
            }}
        >
            {/* Parte superior com onClick */}
            <Box
                onClick={isButton ? () => handleBankSelection(bank) : null}
                sx={{
                    cursor: isButton ? 'pointer' : 'default',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '1em', md: '1.2em' },
                        color: isButton && state.chosenbank === bank ? 'white' : 'black',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '1em', md: '1.2em' },
                        color: isButton && state.chosenbank === bank ? 'white' : 'black',
                    }}
                >
                    {value}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        marginTop: 1,
                        fontSize: { xs: '0.9em', md: '1em' },
                        color: isButton && state.chosenbank === bank ? 'white' : 'black',
                    }}
                >
                    {description}
                </Typography>
            </Box>
    
            {/* Linha divisória */}
            <Box
                sx={{
                    height: '1px',
                    backgroundColor: '#ccc',
                    margin: '12px 0',
                    width: '100%',
                }}
            />
    
            {/* Parte inferior sem onClick */}
            {pix && (
                <Box
                    sx={{
                        marginTop: 0,
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: { xs: '1em', md: '1.2em' },
                            fontWeight: 'bold',
                            marginBottom: 1,
                            color: isButton && state.chosenbank === bank ? 'white' : 'black',
                        }}
                    >
                        {"Chave PIX Depósito"}
                    </Typography>
                    {state.user.configData?.keypix &&
                        JSON.parse(state.user.configData.keypix)
                            .filter(chave => chave.status == 1 && chave.chosenbank == bank)
                            .map((chave, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    sx={{
                                        fontSize: { xs: '0.9em', md: '0.9em' },
                                        color: isButton && state.chosenbank === bank ? 'white' : 'black',
                                    }}
                                >
                                    {`${chave.keypix}`}
                                </Typography>
                            ))}
                </Box>
            )}
        </Box>
    );

    if (state.loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Typography variant="h4">Carregando...</Typography>
            </Box>
        );
    }

    // Saldos do gerente (QESH e BMB)
    const saldoQesh = state.multiplebalance.find(balance => balance.bank === 'qesh')?.balance || 0;
    const saldoBmb = state.multiplebalance.find(balance => balance.bank === 'bmb')?.balance || 0;

    return (
        <Container
            maxWidth="lg" // Permite uma largura responsiva
            sx={{
                mt: 14,
                mb: 4,
                width: '100%', // Ajusta automaticamente à tela
                maxWidth: { xs: '90vw', md: '80vw' }, // Configuração responsiva
                padding: { xs: 1, md: 2 },
            }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: 'primary.main' }}>
                        Painel Gerente {state.user?.userData?.name?.split(' ')[0]}
                    </Typography>

                    {/* Card de saldo do gerente com QESH e BMB */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            gap: 2,
                            mb: 4,
                        }}
                    >
                        {renderCard(
                            'SALDO GERENTE',
                            <>
                                {state.availableBanks.qesh && (
                                    <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                        QESH:
                                    </Typography>
                                )}{' '}
                                {state.availableBanks.qesh && Format.converterCentavosParaReais(saldoQesh)}
                                {state.availableBanks.qesh && state.availableBanks.bmb && ' | '}
                                {state.availableBanks.bmb && (
                                    <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                        BMB:
                                    </Typography>
                                )}{' '}
                                {state.availableBanks.bmb && Format.converterCentavosParaReais(saldoBmb)}
                            </>,
                            'Saldos do gerente divididos por banco',
                            false,
                            '',
                            false,
                        )}

                        {renderCard(
                            'SALDO GERAL CONTAS FILHAS',
                            Format.converterCentavosParaReais(state.totalBalance),
                            `Total de contas filhas: ${state.totalContasFilhas}`,
                            false,
                            '',
                            false,
                        )}
                    </Box>

                    {state.availableBanks.qesh && state.availableBanks.bmb && (
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: 'primary.main' }}>
                            Escolha o Liquidante para Operar
                        </Typography>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            gap: 2,
                            mb: 4,
                        }}
                    >
                        {state.availableBanks.qesh &&
                            renderCard(
                                'QESH CONTAS',
                                state.qeshCount,
                                <>
                                    <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                        Saldo QESH:
                                    </Typography>{' '}
                                    {Format.converterCentavosParaReais(state.qeshBalance)}
                                </>,
                                true,
                                'qesh',
                                true,
                            )}
                        {state.availableBanks.bmb &&
                            renderCard(
                                'BMB CONTAS',
                                state.bmbCount,
                                <>
                                    <Typography component="span" sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                                        Saldo BMB:
                                    </Typography>{' '}
                                    {Format.converterCentavosParaReais(state.bmbBalance)}
                                </>,
                                true,
                                'bmb',
                                true,
                            )}
                    </Box>

                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: 10,
                            boxShadow: 10,
                            p: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, color: 'primary.main' }}>
                            Regras
                        </Typography>

                        {/* Lista de regras */}
                        {[
                            {
                                title: 'ATIVO',
                                description: 'A conta está aberta.',
                                titleBgColor: '#27384E', // Fundo do título
                                titleTextColor: '#FFFFFF', // Cor do texto do título
                                descriptionTextColor: '#000000', // Cor do texto da descrição
                            },
                            {
                                title: 'ABERTURA DE CONTA',
                                description:
                                    'Custo de ' + (() => {
                                        if (Array.isArray(state.tariff)) {
                                            const tarifaItem = state?.tariff.find((item) => item.tariffkey === "tarifa_criar_conta_filha");
                                            if (tarifaItem) {
                                                return tarifaItem.typeofcharge === 1
                                                    ? Format.converterCentavosParaReais(tarifaItem.value)
                                                    : Format.converterCentavosEmPorcentagem(tarifaItem.value) + '%';
                                            }
                                        }
                                        return '';
                                    })() + ' debitado da conta mãe. PIX CPF só poderá ser ativado após 2 minutos da conta aberta (temporizador somente na liquidante BMB).',
                                titleBgColor: '#27384E',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'SAQUE CONTA FILHA',
                                description:
                                    'Será aplicada taxa de ' + (() => {
                                        if (Array.isArray(state.tariff)) {
                                            const tarifaItem = state?.tariff.find((item) => item.tariffkey === "tarifa_saque_filha");
                                            if (tarifaItem) {
                                                return tarifaItem.typeofcharge === 1
                                                    ? Format.converterCentavosParaReais(tarifaItem.value)
                                                    : Format.converterCentavosEmPorcentagem(tarifaItem.value) + '%';
                                            }
                                        }
                                        return '';
                                    })() + ' e o saldo restante será transferido para conta mãe automaticamente.',
                                titleBgColor: '#27384E',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'ATIVAÇÃO DE PIX E QRCODE',
                                description:
                                    'Só será pago via QR CODE quando a conta filha estiver com PIX ativo. Máximo de 5x ativação de pix e pagamento via QR CODE (filtros aplicados somente na liquidante BMB).',
                                titleBgColor: '#27384E',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'TEMPO CHAVE PIX',
                                description:
                                    'A chave pix ficará ativa por 1hr, após esse tempo desativará novamente. É possível ativar ela novamente, caso seja necessário.',
                                titleBgColor: '#27384E',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'RECUSADO NO BANCO',
                                description: 'A conta não foi aberta no banco.',
                                titleBgColor: '#27384E',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'ENCERRADA',
                                description:
                                    'A conta foi encerrada definitivamente (Contas da liquidante BMB têm duração somente de 48h, após o período será excluída e o saldo perdido).',
                                titleBgColor: '#27384E',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'NÃO VENDA E NEM ALUGUE ACESSO',
                                description: 'Você será banido.',
                                titleBgColor: '#B71C1C', // Vermelho escuro
                                titleTextColor: '#FFFFFF', // Branco
                                descriptionTextColor: '#000000', // Preto
                            },
                            {
                                title: 'NÃO USE A FINTECH PARA APLICAR GOLPES',
                                description: 'Você será banido.',
                                titleBgColor: '#B71C1C',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'NÃO USE QUALQUER TIPO DE BOT',
                                description: 'Você será banido.',
                                titleBgColor: '#B71C1C',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'NÃO USE A FINTECH PARA VERIFICAR CHAVE PIX',
                                description: 'Você será banido.',
                                titleBgColor: '#B71C1C',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                            {
                                title: 'Em todos os casos de abuso/banimento sua conta será bloqueada com saldo e tudo.',
                                description: '',
                                titleBgColor: '#B71C1C',
                                titleTextColor: '#FFFFFF',
                                descriptionTextColor: '#000000',
                            },
                        ].map((rule, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' }, // Coluna em telas menores, linha em telas maiores
                                    alignItems: { xs: 'flex-start', sm: 'center' }, // Alinhamento flexível
                                    gap: 2, // Espaçamento entre os elementos
                                    width: '100%', // Garante que os elementos ocupem toda a largura disponível
                                }}
                            >
                                {/* Título com caixa pequena */}
                                <Box
                                    sx={{
                                        padding: '3px 10px',
                                        borderRadius: 5,
                                        backgroundColor: rule.titleBgColor, // Fundo do título
                                        color: rule.titleTextColor, // Texto do título
                                        whiteSpace: { xs: 'normal', sm: 'nowrap' }, // Permite quebra de linha em telas pequenas
                                        marginBottom: { xs: 1, sm: 0 }, // Espaço adicional abaixo do título em telas pequenas
                                    }}
                                >
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', margin: 0 }}>
                                        {rule.title}
                                    </Typography>
                                </Box>

                                {/* Descrição abaixo em telas pequenas */}
                                {rule.description && (
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: rule.descriptionTextColor, // Texto da descrição
                                            textAlign: { xs: 'left', sm: 'inherit' }, // Alinha o texto à esquerda em telas menores
                                        }}
                                    >
                                        {rule.description}
                                    </Typography>
                                )}
                            </Box>

                        ))}
                    </Box>
                </Grid>

            </Grid>
        </Container>

    );
};

export default Home;
