import React, { useState, useEffect } from 'react';
import TransferenciaInterna from './TransferenciaInterna';
import ConsultarExtrato from './ConsultarExtrato';
import ComprovanteModal from './ComprovanteModal';
import CriarContas from './CriarContas';
import ConsultarContasLQ from './ConsultarContasLQ';
import AtualizarSaldoLQ from './AtualizarSaldoLQ';

import * as Funcoes from '../../functions/functions';
import * as Formatar from '../../functions/Format';

import {
  Container,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';

const PainelTransferencia = ({ user, checkAuthentication }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [selectedComprovante, setSelectedComprovante] = useState(null);

  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
    }
    fetchDatacheck();
  }, []);

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenComprovante = (transfer) => {

    setSelectedComprovante({
      data: Formatar.formatarDateSave(transfer.createdAt),
      protocolo: transfer.id,
      idEndToEnd: transfer.endToEnd,
      contaOrigem: {
        banco: transfer.debtor.account?.ispb || "N/A",
        cpfCnpj: transfer.debtor?.document || "N/A",
        agencia: transfer.debtor.account?.branch || "N/A",
        conta: transfer.debtor?.account?.number || "N/A",
        correntista: transfer.debtor?.name || "N/A",
      },
      contaDestino: {
        banco: transfer.creditor.account?.ispb || "N/A",
        agencia: transfer.creditor.account?.branch || "N/A",
        conta: transfer.creditor.account?.number || "N/A",
        tipo: transfer.creditor.account?.type || "N/A",
        cpfCnpj: transfer.creditor?.document || "N/A",
        nome: transfer.creditor?.name || "N/A",
        chave: transfer.creditor?.key || "N/A",
      },
      descricao: "Transferência via PIX",
      dataSolicitacao: Formatar.formatarDateSave(transfer.createdAt),
      dataEfetivacao: transfer.updatedAt
        ? Formatar.formatarDateSave(transfer.updatedAt)
        : Formatar.formatarDateSave(transfer.createdAt),
      valor: Formatar.formatarMoeda(transfer.amount),
    });

  };

  const handleCloseComprovante = () => {
    setSelectedComprovante(null);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 14, mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        Painel ADM
      </Typography>

      <TransferenciaInterna
        handleSnackbar={handleSnackbar}
        loading={loading}
        setLoading={setLoading}
      />

      <ConsultarExtrato
        handleSnackbar={handleSnackbar}
        loading={loading}
        setLoading={setLoading}
        handleOpenComprovante={handleOpenComprovante}
      />

      <CriarContas
        handleSnackbar={handleSnackbar}
        loading={loading}
        setLoading={setLoading}
        handleOpenComprovante={handleOpenComprovante}
        user={user}
      />

      <ConsultarContasLQ
        handleSnackbar={handleSnackbar}
        loading={loading}
        setLoading={setLoading}
        handleOpenComprovante={handleOpenComprovante}
      />

      <AtualizarSaldoLQ
        handleSnackbar={handleSnackbar}
        loading={loading}
        setLoading={setLoading}
        handleOpenComprovante={handleOpenComprovante}
      />

      <ComprovanteModal
        open={Boolean(selectedComprovante)}
        comprovante={selectedComprovante}
        onClose={handleCloseComprovante}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{
          width: '100%',
          fontSize: '1.50rem', // Aumenta em 15%
        }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PainelTransferencia;
