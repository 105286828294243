export const columns = [
    { id: 'id', label: 'ID' },
 /*    { id: 'createdAt', label: 'Data' }, */
    { id: 'userData.name', label: 'Nome' },
    { id: 'ag', label: 'Conta' },
    { id: 'userData.document', label: 'Documento' }, 
     /*{ id: 'userData.telephone', label: 'Telefone' }, */
    { id: 'configData.balance', label: 'Saldo' },
    { id: 'keyPix', label: 'PIX' },
    { id: 'evp', label: 'EVP' },
  ];
  